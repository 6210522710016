import { graphql } from "gatsby";
import React from "react";
import CaseStudyTemplate from "../../templates/CaseStudy/CaseStudy";

const Report = ({ data: { article } }) => {
  return <CaseStudyTemplate article={article} />;
};

export default Report;

export const query = graphql`
  query GetReport($slug: String) {
    article: strapiCaseStudies(slug: { eq: $slug }) {
      id
      published_at
      publish_at
      mediaType
      headingText
      headerCopy
      hubspotCode
      slug
      confirmationMsg
      mainImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              height: 720
              width: 720
              placeholder: DOMINANT_COLOR
            )
          }
          publicURL
        }
        url
      }
      detailPanel {
        copy
        colorTheme
        imagePosition
        image {
          localFile {
            publicURL
          }
          alternativeText
        }
      }
      metaData {
        pageTitle
        metadescription
      }
    }
  }
`;
